import React from 'react';
import { useWindowLocation } from './use-window-location.hook';

export interface LocationInformation {
    pageName: PageLocationInfo;
    pageUrl: PageLocationInfo;
}

export interface PageLocationInfo {
    prior: string;
    current: string;
}

export const useLocationInformation = (): LocationInformation => {
    const location = useWindowLocation();

    const [pageInfo, setPageInfo] = React.useState<LocationInformation>({
        pageName: { prior: null, current: null },
        pageUrl: { prior: null, current: null }
    });

    const locationInformation = React.useMemo(() => {
        const { pathname, url } = location;

        const updatedInfo = {
            pageName: {
                ...pageInfo.pageName,
                prior: pageInfo.pageName.current,
                current: pathname
            },
            pageUrl: {
                ...pageInfo.pageUrl,
                prior: pageInfo.pageUrl.current,
                current: url
            }
        }

        setPageInfo(updatedInfo);
        return updatedInfo;
    }, [location.pathname, location.url])

    return locationInformation;
}
