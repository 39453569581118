import React from "react";

export interface WindowLocation {
    pathname: string;
    url: string;
}

export const useWindowLocation = (): WindowLocation => {
    const [location, setLocation] = React.useState(window.location);

    const listenToPopstateEvents = () => setLocation(window.location);

    React.useEffect(() => {
        window.addEventListener("popstate", listenToPopstateEvents);
        return () => window.removeEventListener("popstate", listenToPopstateEvents);
    }, []);

    const { pathname, href } = location;
    return { pathname, url: href };
};
